import request from '@/utils/request'

export function searcgMachinist(data) {
  return request({
    url: `/search/machinist/maplst`,
    method: 'post',
    data
  })
}
export function filterType(params) {
  return request({
    url: `/search/machinist/filterType`,
    method: 'get',
    params
  })
}
export function filterDriveAge(params) {
  return request({
    url: `/search/machinist/filterDriveAge`,
    method: 'get',
    params
  })
}
export function searchMap(data) {
  return request({
    url: `/search/machinist/map`,
    method: 'post',
    data
  })
}
export function areaBounds(params) {
  return request({
    url: `/search/areaBounds`,
    method: 'get',
    params
  })
}
export function indexInfo(params) {
  return request({
    url: `/index/info`,
    method: 'get',
    params
  })
}
