import Cookies from 'js-cookie'

const TokenKey = 'BJHZLQ'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { domain: process.env.VUE_APP_ROOT })
}

export function removeToken() {
  return Cookies.remove(TokenKey, { domain: process.env.VUE_APP_ROOT })
}

export function getUserInfo() {
  return Cookies.get('user')
}

export function setUserInfo(data) {
  return Cookies.set('user', data)
}
