import "core-js/modules/esnext.iterator.map.js";
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "map_top"
};
const _hoisted_2 = {
  class: "map_top_nav"
};
const _hoisted_3 = {
  class: "map_nav_left"
};
const _hoisted_4 = {
  key: 0,
  class: "map_user_handle"
};
const _hoisted_5 = {
  key: 1,
  class: "map_user_handle"
};
const _hoisted_6 = {
  class: "map_nav_right"
};
const _hoisted_7 = {
  class: "map_user_handle"
};
const _hoisted_8 = ["href"];
const _hoisted_9 = ["href"];
const _hoisted_10 = ["href"];
const _hoisted_11 = ["href"];
const _hoisted_12 = ["href"];
const _hoisted_13 = ["href"];
const _hoisted_14 = ["href"];
const _hoisted_15 = ["href"];
const _hoisted_16 = ["href"];
const _hoisted_17 = {
  class: "map_plane map_filter_box"
};
const _hoisted_18 = {
  class: "map_filter_content"
};
const _hoisted_19 = {
  class: "map_filter_item"
};
const _hoisted_20 = {
  class: "map_filter_point"
};
const _hoisted_21 = {
  class: "map_mini_select"
};
const _hoisted_22 = {
  class: "map_mini_select_top"
};
const _hoisted_23 = {
  class: "map_mini_map"
};
const _hoisted_24 = {
  class: "search_input"
};
const _hoisted_25 = {
  class: "map_mini_range"
};
const _hoisted_26 = {
  class: "map_mini_bottom"
};
const _hoisted_27 = {
  class: "map_filter_item"
};
const _hoisted_28 = {
  class: "map_filter_item"
};
const _hoisted_29 = {
  class: "map_filter_item no_bg"
};
const _hoisted_30 = {
  class: "map_filter_item no_bg"
};
const _hoisted_31 = {
  class: "map_plane map_list"
};
const _hoisted_32 = {
  class: "map_list_plane"
};
const _hoisted_33 = {
  key: 0,
  class: "map_list_layout"
};
const _hoisted_34 = {
  class: "map_list_filter"
};
const _hoisted_35 = ["onClick"];
const _hoisted_36 = ["onClick"];
const _hoisted_37 = {
  class: "map_list_item_position"
};
const _hoisted_38 = {
  class: "map_list_item_top"
};
const _hoisted_39 = ["src"];
const _hoisted_40 = {
  class: "map_list_name"
};
const _hoisted_41 = {
  key: 0,
  class: "map_list_tag"
};
const _hoisted_42 = {
  class: "map_list_desc"
};
const _hoisted_43 = {
  class: "map_list_mini_box"
};
const _hoisted_44 = {
  class: "map_list_item_bottom"
};
const _hoisted_45 = {
  class: "map_list_item_bottom_item"
};
const _hoisted_46 = {
  key: 0,
  class: "nodata"
};
const _hoisted_47 = {
  key: 1,
  class: "nodata"
};
import Cookies from 'js-cookie';
import AMapLoader from '@amap/amap-jsapi-loader';
import { ref, reactive, onMounted, watch } from 'vue';
import { searcgMachinist, filterType, filterDriveAge, searchMap, areaBounds, indexInfo } from '@/api/home';
export default {
  __name: 'Home',
  setup(__props) {
    const URL = process.env.VUE_APP_INDEX;
    let userName = ref('');
    if (Cookies.get('BJHZLQ')) {
      indexInfo().then(res => {
        userName.value = res.data.username;
      });
    }
    const searchForm = reactive({});
    let ageOptions = ref([]);
    let typeOptions = ref([]);
    let showList = ref(true);
    function switchList() {
      showList.value = !showList.value;
    }
    function toUrl(link) {
      window.location.href = URL + link;
    }
    const filterList = ['综合排序', '关注量', '评论数'];
    let activeIndex = ref(0);
    function switchFilterIndex(index) {
      activeIndex.value = index;
    }
    let map;
    onMounted(() => {
      filterType().then(res => {
        typeOptions.value = res.data;
      });
      filterDriveAge().then(res => {
        ageOptions.value = res.data;
      });
      initMap();
    });
    const currentLnglat = ref({});
    function getMouseOver(e) {
      currentLnglat.value = e.lnglat;
    }
    let showOne;
    function initMap() {
      AMapLoader.load({
        key: 'eb3cdd67e7988a5b9b39a8d0b13b236c',
        version: '2.0'
      }).then(AMap => {
        map = new AMap.Map('container', {
          zoom: 4.5,
          animateEnable: true,
          resizeEnable: true,
          center: [112.565026, 37.808929]
        });
        map.on('mousewheel', getZoom);
        map.on('moveend', mapMovestart);
        // map.on('mouseover', getMouseOver)
        map.on('click', e => {
          showOne = false;
          if (markerOne) {
            map.remove(markerOne);
          }
          getMouseOver(e);
        });
        showPoints();
        fetchMoveList();
      });
    }

    // 监听鼠标平移
    function mapMovestart() {
      if (isMove) return;
      if (searchForm.distance && searchForm.distance.type === 'distance') return;
      searchForm.last_sort = undefined;
      searchForm.lng = null;
      searchForm.distance = {
        type: 'bounding_box',
        distance: '',
        points: getBounds()
      };
      pointForm.lng = null;
      fetchMoveList();
      showPoints();
    }
    let proZoom = 7;
    let cityZoom = 9;
    let areaZoom = 11;
    function getBounds() {
      const northwest = map.getBounds().getNorthWest(); //西北
      const eastsouth = map.getBounds().getSouthEast(); //东南
      const points = [{
        lng: northwest.lng,
        lat: northwest.lat
      }, {
        lng: eastsouth.lng,
        lat: eastsouth.lat
      }];
      return points;
    }

    // 监听滚轮变化
    function getZoom() {
      if (searchForm.distance && searchForm.distance.type === 'distance') return;
      searchForm.last_sort = undefined;
      searchForm.lng = null;
      searchForm.distance = {
        type: 'bounding_box',
        distance: '',
        points: getBounds()
      };
      let zoom = map.getZoom();
      if (pointForm.type == 0) {
        if (zoom < areaZoom) {
          pointForm.type = 3;
          showPoints();
          fetchMoveList();
        }
      } else if (pointForm.type == 3) {
        if (zoom > areaZoom) {
          pointForm.type = 0;
          showPoints();
          fetchMoveList();
        } else if (zoom < cityZoom) {
          pointForm.type = 2;
          showPoints();
          fetchMoveList();
        }
      } else if (pointForm.type === 2) {
        if (zoom > cityZoom) {
          pointForm.type = 3;
          showPoints();
          fetchMoveList();
        } else if (zoom < proZoom) {
          searchForm.distance = {};
          pointForm.type = 1;
          showPoints();
          fetchMoveList();
        }
      } else if (pointForm.type === 1) {
        if (zoom > proZoom) {
          pointForm.type = 2;
          showPoints();
          fetchMoveList();
        }
      }
    }
    let driveList = ref([]);
    let listTotal = ref(0);
    let isContinue = true;
    function fetchList() {
      if (!isContinue) return;
      if (!searchForm.lng) {
        const mapCenter = map.getCenter();
        searchForm.lng = mapCenter.lng;
        searchForm.lat = mapCenter.lat;
      }
      if (searchForm.last_sort !== null) {
        isContinue = false;
        searcgMachinist(searchForm).then(res => {
          isClear = false;
          if (searchForm.last_sort) {
            if (res.data.data) {
              driveList.value = driveList.value.concat(res.data.data);
            }
          } else if (searchForm.last_sort === undefined) {
            if (res.data.data) {
              driveList.value = res.data.data;
            } else {
              driveList.value = [];
            }
            listTotal.value = res.data.total;
          }
          searchForm.last_sort = res.data.last_sort;
          isContinue = true;
        });
      }
    }
    function fetchMoveList() {
      const mapCenter = map.getCenter();
      searchForm.lng = mapCenter.lng;
      searchForm.lat = mapCenter.lat;
      const moveForm = Object.assign({}, searchForm);
      moveForm.distance = {
        type: 'bounding_box',
        distance: '',
        points: getBounds()
      };
      delete moveForm.last_sort;
      searcgMachinist(moveForm).then(res => {
        isClear = false;
        if (res.status) {
          if (res.data.data) {
            driveList.value = res.data.data;
          } else {
            driveList.value = [];
          }
          listTotal.value = res.data.total;
          searchForm.last_sort = res.data.last_sort;
        }
      });
    }
    let goFetch = true;
    function getScroll(e) {
      if (e.target.scrollHeight - e.target.scrollTop < 1000) {
        if (!goFetch) return;
        goFetch = false;
        setTimeout(function () {
          fetchList();
          goFetch = true;
        }, 500);
      }
    }
    let popoverVisible = ref(false);
    let miniMap;
    let chooseMapMarker;
    let isMarker = false;
    let chooseAddress = ref('');
    let applyAddress = ref('');
    let search_value = ref('');
    watch(popoverVisible, val => {
      if (val) {
        AMapLoader.load({
          key: 'eb3cdd67e7988a5b9b39a8d0b13b236c',
          version: '2.0',
          AMapUI: {
            version: '1.1',
            plugins: ['overlay/SimpleMarker']
          }
        }).then(AMap => {
          miniMap = new AMap.Map('mini_container', {
            zoom: 5,
            animateEnable: true,
            center: [112.582098, 37.828191]
          });
          window.AMapUI.loadUI(['misc/PoiPicker'], function (PoiPicker) {
            var poiPicker = new PoiPicker({
              input: 'tipinput'
            });
            //选取了某个POI
            poiPicker.on('poiPicked', function (poiResult) {
              var position = new AMap.LngLat(poiResult.item.location.lng, poiResult.item.location.lat); // 标准写法
              miniMap.setCenter(position);
              miniMap.setZoom(13);
              if (isMarker) {
                miniMap.remove(chooseMapMarker);
              }
              chooseMapMarker = new AMap.Marker({
                position: [poiResult.item.location.lng, poiResult.item.location.lat],
                offset: new AMap.Pixel(0, 0)
              });
              chooseMapMarker.setMap(miniMap);
              searchForm.lng = poiResult.item.location.lng;
              searchForm.lat = poiResult.item.location.lat;
              pointForm.lng = poiResult.item.location.lng;
              pointForm.lat = poiResult.item.location.lat;
              getLocation();
              isMarker = true;
            });
          });
          miniMap.on('click', e => {
            if (isMarker) {
              miniMap.remove(chooseMapMarker);
            }
            chooseMapMarker = new AMap.Marker({
              position: [e.lnglat.lng, e.lnglat.lat],
              offset: new AMap.Pixel(0, 0)
            });
            chooseMapMarker.setMap(miniMap);
            searchForm.lng = e.lnglat.lng;
            searchForm.lat = e.lnglat.lat;
            pointForm.lng = e.lnglat.lng;
            pointForm.lat = e.lnglat.lat;
            getLocation();
            isMarker = true;
          });
        });
      }
    });
    function getLocation() {
      AMapLoader.load({}).then(AMap => {
        AMap.plugin('AMap.Geocoder', function () {
          let geocoder = new AMap.Geocoder({});
          let lnglat = [searchForm.lng, searchForm.lat];
          geocoder.getAddress(lnglat, function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
              chooseAddress.value = result.regeocode.formattedAddress;
            }
          });
        });
      });
    }
    function popoverHandle() {
      popoverVisible.value = !popoverVisible.value;
    }
    function getMyLocation() {
      let mapObj = new AMap.Map('mini_container');
      mapObj.plugin('AMap.Geolocation', function () {
        let geolocation = new AMap.Geolocation({});
        mapObj.addControl(geolocation);
        geolocation.getCurrentPosition((status, res) => {
          if (status == 'complete') {
            searchForm.lng = res.position.lng;
            searchForm.lat = res.position.lat;
          } else {
            ElMessage.error('定位获取失败，请稍候再试！');
          }
        });
      });
      mapObj.on('click', e => {
        if (isMarker) {
          mapObj.remove(chooseMapMarker);
        }
        chooseMapMarker = new AMap.Marker({
          position: [e.lnglat.lng, e.lnglat.lat],
          offset: new AMap.Pixel(0, 0)
        });
        chooseMapMarker.setMap(mapObj);
        searchForm.lng = e.lnglat.lng;
        searchForm.lat = e.lnglat.lat;
        getLocation();
        isMarker = true;
      });
    }
    let distanceVal = ref(50);
    function choosePoint() {
      searchForm.distance = {
        type: 'distance',
        distance: distanceVal
      };
      applyAddress.value = chooseAddress.value;
      pointForm.type = 0;
      searchForm.last_sort = undefined;
      map.remove(markers);
      fetchList();
      showPoints();
      showCircle();
      popoverHandle();
    }

    //地图画圆
    let circle;
    let isCircle = false;
    function showCircle() {
      AMapLoader.load({}).then(AMap => {
        if (isCircle) {
          map.remove(circle);
        }
        //画圆
        circle = new AMap.Circle({
          center: new AMap.LngLat(searchForm.lng, searchForm.lat),
          // 圆心位置
          radius: distanceVal.value * 1000,
          //半径
          strokeColor: "#0091ea",
          //线颜色
          strokeOpacity: 1,
          //线透明度
          strokeWeight: 1,
          //线粗细度
          fillColor: "#80d8ff",
          //填充颜色
          fillOpacity: 0.35 //填充透明度
        });
        map.add(circle);
        map.setFitView(); //视口自适应
        isCircle = true;
      });
    }
    let markerOne;
    function selectItem(item) {
      showOne = true;
      if (markerOne) {
        map.remove(markerOne);
      }
      const content = `<div class="map_driver_item">
                      <div class="map_drive_detail" style="display:flex">
                        <img class="map_drive_detail_avatar" src="${item.avatar}">
                        <div class="map_drive_detail_right">
                          <div class="map_drive_detail_top">
                            <div>${item.name}</div>
                            <a target="_blank" href="${URL + '/Job/card_detail.html?id=' + item.id}" class="map_drive_detail_btn">详情</a>
                          </div>
                          <div class="map_drive_detail_desc">${item.category_v ? item.category_v.join(' | ') : ''}</div>
                          <div class="map_drive_detail_desc">
                            <a class="login_link" target="_blank" href="${URL + '/Job/card_detail.html?id=' + item.id}">点击查看详情 →</a>
                          </div>
                        </div>
                      </div>
                      <img src="/images/angle.png">
                      <img class="map_driver_item_image" src="/images/driver.png">
                  </div>`;
      markerOne = new AMap.Marker({
        content,
        position: bd_decrypt(item.lnglat[0], item.lnglat[1]),
        // 基点位置
        offset: new AMap.Pixel(-36, -36) // 相对于基点的偏移位置
      });
      // markerOne.on('click', () => {
      //   window.open(URL + `/Job/card_detail.html?id=${item.id}`)
      // })
      map.add(markerOne);
    }
    function bd_decrypt(bd_lng, bd_lat) {
      // var X_PI = (Math.PI * 3000.0) / 180.0;
      // var x = bd_lng - 0.0065;
      // var y = bd_lat - 0.006;
      // var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
      // var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
      // var gg_lng = z * Math.cos(theta);
      // var gg_lat = z * Math.sin(theta);
      return [bd_lng, bd_lat];
    }
    let pointForm = {
      type: 1
    };
    let markers = [];
    let isMove = false;
    const showPoints = () => {
      AMapLoader.load({}).then(AMap => {
        fetchData();
        function fetchData() {
          if (!pointForm.lng) {
            const mapCenter = map.getCenter();
            pointForm.lng = mapCenter.lng;
            pointForm.lat = mapCenter.lat;
          }
          searchMap({
            ...searchForm,
            ...pointForm
          }).then(res => {
            if (searchForm.distance && searchForm.distance.type === 'distance' && pointForm.type === 0) {
              map.remove(markers);
            }
            if (res.data.data && res.data.data.length) {
              if (res.data.data[0].type) {
                pointForm.type = res.data.data[0].type;
              } else {
                pointForm.type = 0;
              }
              markers = res.data.data.map(item => {
                let content, markerItem;
                if (pointForm.type === 0) {
                  content = `<div class="map_driver_item">
                            <div class="map_driver_info">
                              ${item.name} ${item.category_v ? item.category_v.join(' | ') : ''}
                            </div>
                            <div class="map_drive_detail">
                              <img class="map_drive_detail_avatar" src="${item.avatar}">
                              <div class="map_drive_detail_right">
                                <div class="map_drive_detail_top">
                                  <div>${item.name}</div>
                                  <a target="_blank" href="${URL + '/Job/card_detail.html?id=' + item.id}" class="map_drive_detail_btn">详情</a>
                                </div>
                                <div class="map_drive_detail_desc">${item.category_v ? item.category_v.join(' | ') : ''}</div>
                                <div class="map_drive_detail_desc">
                                  <a class="login_link" target="_blank" href="${URL + '/Job/card_detail.html?id=' + item.id}">点击查看详情 →</a>
                                </div>
                              </div>
                            </div>
                            <img src="/images/angle.png">
                            <img class="map_driver_item_image" src="/images/driver.png">
                        </div>`;
                  markerItem = new AMap.Marker({
                    content,
                    position: bd_decrypt(item.lnglat[0], item.lnglat[1]),
                    // 基点位置
                    offset: new AMap.Pixel(-36, -36) // 相对于基点的偏移位置
                  });
                } else {
                  content = `<div class="map_point"><div>${item.name}</div><div><b>${item.doc_count}</b>名</div></div>`;
                  markerItem = new AMap.Marker({
                    content,
                    position: item.center,
                    // 基点位置
                    offset: new AMap.Pixel(-36, -36) // 相对于基点的偏移位置
                  });
                }
                markerItem.data = item;
                markerItem.on('click', getNextPoint);
                return markerItem;
              });
              if (searchForm.distance && searchForm.distance.type === 'distance') {
                map.add(markers);
              } else {
                map.clearMap();
                map.add(markers);
                if (showOne) {
                  map.add(markerOne);
                }
              }
            } else {
              if (res.status) {
                if (searchForm.distance && searchForm.distance.type === 'distance') {
                  map.remove(markers);
                } else {
                  map.clearMap();
                }
                if (showOne) {
                  map.add(markerOne);
                }
              }
            }
          });
        }
        function getNextPoint(e) {
          isMove = true;
          const item = e.target.data;
          if (pointForm.type === 0) {
            // window.open(URL + `/Job/card_detail.html?id=${item.id}`)
            return;
          }
          map.remove(markers);
          markers = [];
          pointForm.type += 1;
          pointForm.lng = item.center[0];
          pointForm.lat = item.center[1];
          if (pointForm.type === 4) pointForm.type = 0;
          areaBounds({
            areaId: item.id
          }).then(res => {
            const bounds = new AMap.Bounds([res.data.bounds.bounds.top_left.lon, res.data.bounds.bounds.top_left.lat], [res.data.bounds.bounds.bottom_right.lon, res.data.bounds.bounds.bottom_right.lat]);
            map.setBounds(bounds);
            searchForm.distance = {
              type: 'bounding_box',
              distance: '',
              points: [{
                lng: parseFloat(res.data.bounds.bounds.top_left.lon.toFixed(6)),
                lat: parseFloat(res.data.bounds.bounds.top_left.lat.toFixed(6))
              }, {
                lng: parseFloat(res.data.bounds.bounds.bottom_right.lon.toFixed(6)),
                lat: parseFloat(res.data.bounds.bounds.bottom_right.lat.toFixed(6))
              }]
            };
            delete searchForm.last_sort;
            delete searchForm.lng;
            setTimeout(() => {
              isMove = false;
              fetchList();
              fetchData();
            }, 500);
          });
        }
      });
    };
    function selectButton(type) {
      searchForm[type] = searchForm[type] ? 0 : 1;
    }
    function clearMiniFilter() {
      popoverVisible.value = false;
      search_value.value = '';
      chooseAddress.value = '';
      applyAddress.value = '';
      searchForm.last_sort = undefined;
      map.remove(circle);
      searchForm.distance = {
        type: 'bounding_box',
        distance: '',
        points: getBounds()
      };
      let zoom = map.getZoom();
      // let proZoom = 7
      // let cityZoom = 9
      // let areaZoom = 11
      if (zoom > areaZoom) {
        pointForm.type = 0;
      } else if (zoom > cityZoom && zoom < areaZoom) {
        pointForm.type = 3;
      } else if (zoom > proZoom && zoom < cityZoom) {
        pointForm.type = 2;
      } else if (zoom < proZoom) {
        pointForm.type = 1;
      }
      showPoints();
      fetchMoveList();
    }
    let isClear = false;
    function clearFilter() {
      popoverVisible.value = false;
      isClear = true;
      searchForm.last_sort = undefined;
      searchForm.distance = {};
      searchForm.category = '';
      searchForm.driving_age = '';
      searchForm.cert = 0;
      searchForm.experience = 0;
      searchForm.lng = null;
      search_value.value = '';
      pointForm = {
        type: 1
      };
      chooseAddress.value = '';
      applyAddress.value = '';
      activeIndex.value = 0;
      initMap();
      showOne = false;
    }
    watch([() => searchForm.category, () => searchForm.driving_age, () => searchForm.cert, () => searchForm.experience], val => {
      if (!isClear) {
        searchForm.last_sort = undefined;
        if (searchForm.distance && searchForm.distance.type === 'distance') {
          fetchList();
        } else {
          fetchMoveList();
        }
        showPoints();
      }
    });
    watch(activeIndex, () => {
      searchForm.last_sort = undefined;
      searchForm.order = 'desc';
      if (activeIndex.value === 1) {
        searchForm.sort_field = 'browse_num';
      } else if (activeIndex.value === 2) {
        searchForm.sort_field = 'comment_num';
      } else {
        searchForm.sort_field = '';
        searchForm.order = '';
      }
      fetchMoveList();
    });
    return (_ctx, _cache) => {
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_popover = _resolveComponent("el-popover");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      return _openBlock(), _createElementBlock(_Fragment, null, [_cache[32] || (_cache[32] = _createElementVNode("div", {
        id: "container"
      }, null, -1)), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[11] || (_cache[11] = _createElementVNode("img", {
        src: "/images/logo.png"
      }, null, -1)), _unref(userName) ? (_openBlock(), _createElementBlock("span", _hoisted_4, [_createElementVNode("span", null, _toDisplayString(_unref(userName)), 1), _cache[9] || (_cache[9] = _createTextVNode(" | ")), _createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = $event => toUrl('/Login/logout.html'))
      }, "退出")])) : (_openBlock(), _createElementBlock("span", _hoisted_5, [_createElementVNode("span", {
        onClick: _cache[1] || (_cache[1] = $event => toUrl('/Login/login.html'))
      }, "登录"), _cache[10] || (_cache[10] = _createTextVNode(" | ")), _createElementVNode("span", {
        onClick: _cache[2] || (_cache[2] = $event => toUrl('/Login/register.html'))
      }, "注册")]))]), _createElementVNode("div", _hoisted_6, [_createElementVNode("span", _hoisted_7, [_createElementVNode("a", {
        href: _unref(URL),
        target: "_blank"
      }, "首页", 8, _hoisted_8), _createElementVNode("a", {
        href: _unref(URL) + '/e/list',
        target: "_blank"
      }, "自选直租", 8, _hoisted_9), _createElementVNode("a", {
        href: _unref(URL) + '/Bidrent/bid_rent.html',
        target: "_blank"
      }, "求租信息", 8, _hoisted_10), _createElementVNode("a", {
        href: _unref(URL) + '/job/index.html',
        target: "_blank"
      }, "机手劳务", 8, _hoisted_11), _createElementVNode("a", {
        href: _unref(URL) + '/job/energy.html'
      }, "新能源驾驶员", 8, _hoisted_12), _createElementVNode("a", {
        href: _unref(URL) + '/ProjectTender/lists.html',
        target: "_blank"
      }, "工程招标", 8, _hoisted_13), _createElementVNode("a", {
        href: _unref(URL) + '/Banking/company.html',
        target: "_blank"
      }, "金融服务", 8, _hoisted_14), _createElementVNode("a", {
        href: _unref(URL) + '/Shbase/index.html',
        target: "_blank"
      }, "共享基地", 8, _hoisted_15), _cache[12] || (_cache[12] = _createElementVNode("a", {
        href: "http://lqyy.isms.net.cn:8090/index",
        target: "_blank"
      }, "路桥云油", -1)), _cache[13] || (_cache[13] = _createElementVNode("a", {
        href: "https://iot.zulide.net"
      }, "路桥物联", -1)), _createElementVNode("a", {
        href: _unref(URL) + '/Article/articleList.html',
        target: "_blank"
      }, "新闻中心", 8, _hoisted_16)])])])]), _cache[33] || (_cache[33] = _createElementVNode("div", {
        class: "map_plane map_title"
      }, [_createElementVNode("b", null, "地图找机手"), _createElementVNode("span", null, "海量机手上云，地图精准查找")], -1)), _createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_createVNode(_component_el_popover, {
        placement: "bottom-start",
        width: 700,
        trigger: "click",
        visible: _unref(popoverVisible),
        offset: 18
      }, {
        reference: _withCtx(() => [_createElementVNode("div", {
          class: "map_filter_label",
          onClick: popoverHandle
        }, [_createElementVNode("span", _hoisted_20, _toDisplayString(_unref(applyAddress) ? _unref(applyAddress) : '地图选点'), 1), _createElementVNode("img", {
          class: _normalizeClass({
            svg_top: !_unref(popoverVisible),
            svg_bottom: _unref(popoverVisible)
          }),
          src: "/images/mini_bottom.png"
        }, null, 2)])]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_21, [_createElementVNode("div", _hoisted_22, [_createElementVNode("div", null, [_cache[14] || (_cache[14] = _createTextVNode(" 选中地址")), _createElementVNode("span", null, _toDisplayString(_unref(chooseAddress) ? _unref(chooseAddress) : '请在下方地图上点击一个地址'), 1)]), _createElementVNode("div", {
          class: "map_my_position",
          onClick: getMyLocation
        }, _cache[15] || (_cache[15] = [_createElementVNode("img", {
          src: "/images/position.svg"
        }, null, -1), _createTextVNode(" 我的位置 ")]))]), _createElementVNode("div", _hoisted_23, [_cache[17] || (_cache[17] = _createElementVNode("div", {
          id: "mini_container"
        }, null, -1)), _createElementVNode("div", _hoisted_24, [_cache[16] || (_cache[16] = _createElementVNode("span", null, "地点搜索：", -1)), _withDirectives(_createElementVNode("input", {
          id: "tipinput",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _isRef(search_value) ? search_value.value = $event : search_value = $event),
          placeholder: "请输入地点关键词",
          autocomplete: "off"
        }, null, 512), [[_vModelText, _unref(search_value)]])])]), _createElementVNode("div", _hoisted_25, [_cache[22] || (_cache[22] = _createElementVNode("span", null, "搜索范围", -1)), _createVNode(_component_el_radio_group, {
          modelValue: _unref(distanceVal),
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _isRef(distanceVal) ? distanceVal.value = $event : distanceVal = $event)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_radio, {
            label: 10
          }, {
            default: _withCtx(() => _cache[18] || (_cache[18] = [_createTextVNode(" 10公里以内 ")])),
            _: 1
          }), _createVNode(_component_el_radio, {
            label: 20
          }, {
            default: _withCtx(() => _cache[19] || (_cache[19] = [_createTextVNode(" 20公里以内 ")])),
            _: 1
          }), _createVNode(_component_el_radio, {
            label: 50
          }, {
            default: _withCtx(() => _cache[20] || (_cache[20] = [_createTextVNode(" 50公里以内 ")])),
            _: 1
          }), _createVNode(_component_el_radio, {
            label: 100
          }, {
            default: _withCtx(() => _cache[21] || (_cache[21] = [_createTextVNode(" 100公里以内 ")])),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_26, [_createVNode(_component_el_button, {
          onClick: popoverHandle
        }, {
          default: _withCtx(() => _cache[23] || (_cache[23] = [_createTextVNode(" 取消 ")])),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: clearMiniFilter
        }, {
          default: _withCtx(() => _cache[24] || (_cache[24] = [_createTextVNode(" 清除 ")])),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: choosePoint
        }, {
          default: _withCtx(() => _cache[25] || (_cache[25] = [_createTextVNode(" 检索 ")])),
          _: 1
        })])])]),
        _: 1
      }, 8, ["visible"])]), _createElementVNode("div", _hoisted_27, [_createVNode(_component_el_select, {
        modelValue: searchForm.category,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => searchForm.category = $event),
        style: {
          "width": "110px"
        },
        placeholder: "机种类别",
        clearable: ""
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(typeOptions), (item, index) => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: index,
            label: item,
            value: index
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_28, [_createVNode(_component_el_select, {
        modelValue: searchForm.driving_age,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => searchForm.driving_age = $event),
        style: {
          "width": "100px"
        },
        placeholder: "驾龄",
        clearable: ""
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(ageOptions), (item, index) => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: index,
            label: item,
            value: index
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_29, [_createElementVNode("div", {
        class: _normalizeClass(["map_filter_button", {
          active: searchForm.cert
        }]),
        onClick: _cache[7] || (_cache[7] = $event => selectButton('cert'))
      }, " 职业证书 ", 2)]), _createElementVNode("div", _hoisted_30, [_createElementVNode("div", {
        class: _normalizeClass(["map_filter_button", {
          active: searchForm.experience
        }]),
        onClick: _cache[8] || (_cache[8] = $event => selectButton('experience'))
      }, " 项目经验 ", 2)]), _createElementVNode("div", {
        class: "map_filter_item no_bg"
      }, [_createElementVNode("div", {
        class: "map_filter_delete",
        onClick: clearFilter
      }, _cache[26] || (_cache[26] = [_createElementVNode("img", {
        src: "/images/recovery.svg"
      }, null, -1), _createTextVNode(" 清除条件 ")]))])])]), _createElementVNode("div", _hoisted_31, [_createElementVNode("div", _hoisted_32, [_createElementVNode("span", null, [_cache[27] || (_cache[27] = _createTextVNode("当前区域找到")), _createElementVNode("b", null, _toDisplayString(_unref(listTotal)), 1), _cache[28] || (_cache[28] = _createTextVNode("名机手"))]), _createElementVNode("div", {
        class: "map_list_plane_btn",
        onClick: switchList
      }, [_createElementVNode("img", {
        src: "/images/top.svg",
        class: _normalizeClass({
          svg_bottom: !_unref(showList),
          svg_top: _unref(showList)
        })
      }, null, 2)])])]), _unref(showList) ? (_openBlock(), _createElementBlock("div", _hoisted_33, [_createElementVNode("div", _hoisted_34, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(filterList, (item, index) => {
        return _createElementVNode("span", {
          key: index,
          class: _normalizeClass({
            active: _unref(activeIndex) == index
          }),
          onClick: $event => switchFilterIndex(index)
        }, _toDisplayString(item), 11, _hoisted_35);
      }), 64))]), _createElementVNode("div", {
        class: "map_list_main",
        onScroll: getScroll
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(driveList), item => {
        return _openBlock(), _createElementBlock("div", {
          key: item._id,
          class: "map_list_item",
          onClick: $event => selectItem(item)
        }, [_createElementVNode("div", _hoisted_37, _toDisplayString(item.distance) + "km ", 1), _createElementVNode("div", _hoisted_38, [_createElementVNode("img", {
          src: item.avatar
        }, null, 8, _hoisted_39), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_40, [_createElementVNode("b", null, _toDisplayString(item.name), 1), item.auth ? (_openBlock(), _createElementBlock("div", _hoisted_41, _toDisplayString(item.auth), 1)) : _createCommentVNode("", true)]), _createElementVNode("b", _hoisted_42, _toDisplayString(item.category_v ? item.category_v.join(' | ') : ''), 1), _createElementVNode("div", _hoisted_43, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.label, (v, k) => {
          return _openBlock(), _createElementBlock("div", {
            key: k,
            class: "map_list_mini_tag"
          }, _toDisplayString(v), 1);
        }), 128))])])]), _createElementVNode("div", _hoisted_44, [_createElementVNode("div", _hoisted_45, [_cache[29] || (_cache[29] = _createElementVNode("img", {
          src: "/images/location.svg"
        }, null, -1)), _createTextVNode(_toDisplayString(item.province_v) + _toDisplayString(item.city_v), 1)]), _createElementVNode("div", null, "关注 " + _toDisplayString(item.browse_num) + "　评论 " + _toDisplayString(item.comment_num), 1)])], 8, _hoisted_36);
      }), 128)), _unref(driveList).length && searchForm.last_sort === null ? (_openBlock(), _createElementBlock("div", _hoisted_46, _cache[30] || (_cache[30] = [_createElementVNode("div", null, "已经没有更多了", -1)]))) : _createCommentVNode("", true), !_unref(driveList).length ? (_openBlock(), _createElementBlock("div", _hoisted_47, _cache[31] || (_cache[31] = [_createElementVNode("img", {
        src: "/images/nodata.png"
      }, null, -1), _createElementVNode("div", null, "当前地图范围", -1), _createElementVNode("div", null, "没有找到符合条件的机手", -1)]))) : _createCommentVNode("", true)], 32)])) : _createCommentVNode("", true)], 64);
    };
  }
};